<template>
  <div class="container">
    <div v-if="loading">
      <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
    </div>
    <div v-if="!loading">
      <div v-if="!permissions.read">
        You do not have permission for this page
      </div>
      <div v-if="permissions.read">
        <h1>{{ $route.params.model }}</h1>
        <div class="my-3"><b-link :to="{ name: 'home' }">home</b-link> | <b-link :to="{ name: 'new', params: { model: $route.params.model } }">add</b-link> |</div>
        <div v-if="!loading">
          <div v-for="node in nodes" :key="node.id">
            <router-link :to="{ name: 'view', params: { model: $route.params.model, id: node.id } }">{{ node.name || node.title || node.url }}</router-link> |
            <router-link :to="{ name: 'edit', params: { model: $route.params.model, id: node.id } }">edit</router-link> |
          </div>
        </div>
        <div class="my-3"><b-link :to="{ name: 'home' }">home</b-link> | <b-link :to="{ name: 'new', params: { model: $route.params.model } }">add</b-link> |</div>
      </div>
    </div>
  </div>
</template>

<script>
import ac from '@/libs/accesscontrol'

export default {
  name: 'list',
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
      loading: false,
      nodes: [],
      permissions: {
        read: false,
        update: false,
        create: false,
        delete: false
      }
    }
  },
  created: function () {
    this.permissions.create = ac.can(this.user.acgroups).createAny(this.$route.params.model).granted
    this.permissions.read = ac.can(this.user.acgroups).readAny(this.$route.params.model).granted
    this.permissions.update = ac.can(this.user.acgroups).updateAny(this.$route.params.model).granted
    this.permissions.delete = ac.can(this.user.acgroups).deleteAny(this.$route.params.model).granted
    if (this.permissions.read) {
      this.load()
    } else {
      this.loading = false
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        const response = await this.$Amplify.API.get('cosmos', `/standard/${this.$route.params.model}`)
        this.nodes = response
        this.loading = false
      } catch (e) {
        this.$logger.warn(e)
      }
    }
  }
}
</script>
